@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

/* .sidebar-item-active > svg {
    color: #FFFFFF;
} */

.skeleton-box {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;
}

.skeleton-box:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    animation: shimmer 2s infinite;
    content: '';
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader--small {
  width: 24px;
  height: 24px;
}

.loader--primary {
    border: 5px solid rgba(96, 165, 250, var(--tw-bg-opacity));
    border-bottom-color: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-time-picker__wrapper {
    border: none !important;
}

/* Scrollbar global */

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #919191 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #919191;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

/* React player */

.video-wrapper {
  position: relative;
  padding-top: 56.25%;
}